export default [
  {
    key: 'user',
    sortable: true,
    label: 'user',
  },
  {
    key: 'comment',
    sortable: false,
    label: 'Comment',
  },
  {
    key: 'date',
    sortable: false,
    label: 'Date',
  },

]
